/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
export function onRouteUpdate({ location, prevLocation }) {
  if (location.pathname.indexOf('yhteystiedot') > -1 && window.FB) {
    //window.FB.XFBML.parse()
  }
}
