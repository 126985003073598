// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-tsx": () => import("./../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-tapahtumat-tsx": () => import("./../src/pages/tapahtumat.tsx" /* webpackChunkName: "component---src-pages-tapahtumat-tsx" */),
  "component---src-templates-page-tsx": () => import("./../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-reserve-tsx": () => import("./../src/templates/reserve.tsx" /* webpackChunkName: "component---src-templates-reserve-tsx" */)
}

